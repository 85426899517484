* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  max-width: 90em;
  height: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text-shadow {
  text-shadow: 1px 2px 6px black;
}
.box-shadow {
  box-shadow: 1px 2px 6px black;
}
 